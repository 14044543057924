<template>
    <div class="address-card">
        <div class="h5 font-weight--600">Địa chỉ giao hàng:</div>
        <el-input
        v-model="deliveryAddress"
        class="address-area"
  type="textarea"
  :autosize="{ minRows: 1, maxRows: 100}"
  placeholder="Nhập địa chỉ giao hàng"
  disabled>
</el-input>
    </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    deliveryAddress: {
      get () {
        return this.$store.state.cart.deliveryAddress
      },
      set (deliveryAddress) {
        this.$store.commit('cart/setDeliveryAddress', deliveryAddress)
      }
    }
  }
}
</script>

<style lang="scss">
  .address-area textarea{
    width: 100%; background: transparent; border: none; color: #003b35;
    padding: 0;
    &::placeholder {
      color: #003b35;
      opacity: .75;
    }
    &:disabled {
      background-color: #e5f6ff !important;
    }
  }
  .address-card {
      background-color:#E5F6FF;
      border-radius: 15px;
      padding: 16px;
  }
</style>
