<template>
    <div class="cart-item">
        <div class="cart-item__center">
            <div v-if="cartItem.productName">{{cartItem.productName}} <span v-if="cartItem.productSize">{{cartItem.productSize}}</span></div>
            <div>SKU: <small v-if="cartItem.productCode">{{cartItem.productCode}}</small><small v-if="cartItem.groupCode"> - {{cartItem.groupCode}}</small></div>
        </div>
        <div class="cart-item__right">
            <div class="cart-item__quality">
                <div class="cart-item__quality__number">{{cartItem.quantity}}</div>
            </div>
            <div class="cart-item__unit">{{cartItem.unitDescription}}</div>
            <div class="cart-item__price">{{amoutPrice.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    cartItem: Object
  },
  data () {
    return {}
  },
  computed: {
    amoutPrice () {
      return this.cartItem.price * this.cartItem.quantity
    }
  },
  methods: {
    removeItemCartHandle (productCode) {
      this.$confirm('Bạn muốn xoá sản phẩm này khỏi giỏ hàng?').then(() => {
        this.$store.dispatch('cart/removeItem', { productCode }).then(() => {
          this.$notify({
            duration: 1000,
            title: 'Thành công!',
            message: 'Xoá thành công sản phẩm trong giỏ hàng',
            type: 'success'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .cart-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #59C6BC;
        padding: 16px 0;
        &__left {
            padding: 0 16px;
            input[type=checkbox] {
                width: 30px;
                height: 30px;
            }
        }
        &__center {
            flex: 1;
        }
        &__right {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__price {
            font-size: 20px;
            font-weight: 600;
            color: #B93D00;
            margin-top: 8px;
        }
    }

    .cart-item__quality {
        display: flex;
        align-items: center;
        width: 100px;
        &__minutes, &__plus {
            background-color: #F36F20;
            width: 25px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
                opacity: 0.5;
            }
        }
        &__minutes {
            border-radius: 5px 0 0 5px;
          &--disable {
            pointer-events: auto;
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        &__number {
            flex: 1;
            line-height: 30px;
            padding: 0 16px;
            text-align: center;
        }
        &__plus {
            border-radius: 0 5px 5px 0;
        }
    }
</style>
