<template>
    <div class="cart__info h5 font-weight--sm--500">
      <div class="cart__info__item">
        <div class="cart__info__left">Tổng giá trị thành tiền:</div>
        <div class="cart__info__right" style="color: #B93D00;">{{amountBeforetax.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
      </div>
      <div class="cart__info__item">
        <div class="cart__info__left">VAT:</div>
        <div class="cart__info__right" style="color: #B93D00;">{{taxAmount.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
      </div>
      <div class="cart__info__item" style="color: #B3202E;">
        <div class="cart__info__left">Tổng thanh toán:</div>
        <div class="cart__info__right">{{amountAfterTax.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
      </div>
    </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  computed: {
    taxAmount () {
      return this.$store.getters['cart/taxAmount']
    },
    amountBeforetax () {
      return this.$store.getters['cart/amountBeforetax']
    },
    amountAfterTax () {
      return this.$store.getters['cart/amountAfterTax']
    }
  }

}
</script>

<style lang="scss">
  .cart__info {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &__left {
    }
    &__right {
      flex: 1;
      text-align: right;
    }
  }
</style>
