<template>
  <div class="page checkout-page">
    <Header />
    <HotlineMobileCard />
    <div class="container">
      <div
        class="h5 font-weight--sm--500 text--sm--center mb--sm--3 mb--md--20 mt--sm--3 mt--md--15 forTabletUp"
      >
        Đặt hàng
      </div>
      <div class="mb--sm--6">
        <AddressCard />
      </div>
      <div class="delivery-date">
        <div class="h5">Ngày giao hàng:</div>
        <div>
          <el-date-picker
            v-model="deliveryDate"
            type="date"
            placeholder="Chọn ngày giao"
            value-format="yyyyMMdd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="h5">Sản phẩm đã đặt:</div>
      <div class="row mb--sm--6">
        <div
          class="col col--sm--12 col--md--6"
          v-for="cartItem in cartItems"
          :key="cartItem.productCode"
        >
          <CheckoutProductItem :cartItem="cartItem" />
        </div>
      </div>
      <CartInfo />
      <div class="ctkm">
        <div class="ctkm__label">CTKM:</div>
        <div class="ctkm__list">
          <div class="ctkm__item" v-if="!$_.isEmpty(saleDeal)">
            <div class="ctkm__item__name">{{ saleDeal.name }}</div>
          </div>
          <div class="ctkm__item" v-if="$store.state.customer.promotionCode != '' && $store.state.customer.promotionDes != ''">
            <div class="ctkm__item__name">{{ $store.state.customer.promotionDes }}</div>
          </div>
          <div class="ctkm__item" v-if="$store.state.customer.promotionX1Code != '' && $store.state.customer.promotionX1Des != ''">
            <div class="ctkm__item__name">{{ $store.state.customer.promotionX1Des }}</div>
          </div>
        </div>
      </div>
      <div
        class="h5 mb--sm--5"
        style="background-color:#CBE3E0; border-radius: 15px; padding: 8px 16px; display: flex"
      >
        Ghi chú:
        <span style="flex: 1; margin-left: 8px"
          ><el-input
            v-model="note"
            class="note-area"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 100 }"
            placeholder="Nhập ghi chú"
          >
          </el-input
        ></span>
      </div>
      <div
        class="h5 mb--sm--5"
        style="background-color:#CCE4EC; border-radius: 15px; padding: 8px 16px; display: flex"
        v-if="$store.state.auth.user.debt"
      >
        Công nợ đầu ngày:
        <span style="flex: 1; ; text-align: right">{{
          parseInt($store.getters["auth/getDebt"]).toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND"
          })
        }}</span>
      </div>
      <div
        class="h5 mb--sm--5"
        style="background-color:#E5D5D4; border-radius: 15px; padding: 8px 16px; display: flex"
        v-if="$store.state.auth.user.creditLimit"
      >
        Hạn mức tín dụng còn:
        <span style="flex: 1; text-align: right">{{
          parseInt(
            $store.getters["auth/getCreditLimit"]
          ).toLocaleString("vi-VN", { style: "currency", currency: "VND" })
        }}</span>
      </div>
      <div class="text--sm--right">
        <HdButton
          v-on:click="checkoutSubmitHandle"
          :disable="cartEmpty"
          v-bind:disabled="startTimer"
          >Xác nhận đơn hàng
          <span v-if="startTimer">({{ countDown }})</span></HdButton
        >
      </div>
    </div>
    <Footer />
    <FooterMobile />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FooterMobile from "@/components/FooterMobile.vue";
import AddressCard from "@/components/AddressCard.vue";
import HotlineMobileCard from "@/components/HotlineMobileCard.vue";
import CheckoutProductItem from "@/components/CheckoutProductItem.vue";
import CartInfo from "@/components/CartInfo";
import HdButton from "@/components/HdButton";
import store from "@/store";

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    AddressCard,
    HotlineMobileCard,
    CheckoutProductItem,
    CartInfo,
    HdButton,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() <
              Date.now() +
                3600 * 1000 * (parseInt(store.getters["auth/getGhtc"]) - 24) ||
            time.getTime() >
              Date.now() +
                3600 * 1000 * parseInt(store.getters["auth/getGhtc"]) +
                3600 * 1000 * 24 * 6
          );
        },
        shortcuts: [
          {
            text: "Giao hàng tiêu chuẩn",
            onClick(picker) {
              const date = new Date();
              date.setTime(
                date.getTime() +
                  3600 * 1000 * parseInt(store.getters["auth/getGhtc"])
              );
              picker.$emit("pick", date);
            }
          }
        ]
      },
      countDown: 10,
      startTimer: false
    };
  },
  methods: {
    checkoutSubmitHandle: function(event) {
      if (this.$_.isEmpty(this.deliveryDate)) {
        return this.$alert("Vui lòng chọn ngày giao hàng", "Thông báo", {
          confirmButtonText: "OK",
          type: "warning"
        });
      }
      if (this.$_.isEmpty(this.deliveryAddress)) {
        return this.$alert("Vui lòng nhập địa chỉ giao hàng", "Thông báo", {
          confirmButtonText: "OK",
          type: "warning"
        });
      }
      this.countDownTimer();
      this.$store
        .dispatch("cart/postOrders")
        .then(response => {
          this.$router
            .push(`/confirm-checkout/${response.data.d.orderID}`)
            .catch(() => {});
          this.startTimer = false;
          this.$notify({
            duration: 1000,
            title: "Thành công!",
            message: this.$t("checkout.success"),
            type: "success"
          });
        });
    },
    countDownTimer() {
      this.startTimer = true;
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    fetchCusPromotion() {
      this.$store.dispatch("customer/getCusPromotion");
    }
  },
  computed: {
    deliveryAddress() {
      return this.$store.state.cart.deliveryAddress;
    },
    saleDeal() {
      return this.$store.state.cart.saleDeal;
    },
    deliveryDate: {
      get() {
        return this.$store.state.cart.deliveryDate;
      },
      set(value) {
        this.$store.commit("cart/setDeliveryDate", value);
      }
    },
    cartEmpty() {
      return this.$_.isEmpty(this.cartItems);
    },
    cartItems() {
      return this.$store.getters["cart/checkoutProductItems"];
    },
    note: {
      get() {
        return this.$store.state.cart.note;
      },
      set(note) {
        this.$store.commit("cart/setNote", note);
      }
    }
  },
  watch: {
    cartItems() {
      this.fetchCusPromotion();
    }
  },
  mounted() {
    this.$store.dispatch("setHeaderMobile", {
      heading: true,
      headingText: "Đặt hàng",
      back: true,
      search: false,
      notify: true,
      cart: false
    });
    this.$store.dispatch("cart/setDefaultDeliveryDate");
    this.$store.dispatch("setFooterMobile", false);
    if (this.$store.state.cart.cartSelectedItems.length > 0) {
      this.$store.dispatch("cart/checkDeliveryAddress");
    } else {
      this.$router.push("/cart").catch(() => {});
    }
    this.fetchCusPromotion();
  }
};
</script>

<style lang="scss">
.note-area {
  textarea {
    width: 100%;
    background: transparent;
    border: none;
    color: #003b35;
    &::placeholder {
      color: #003b35;
      opacity: 0.75;
    }
  }
}
</style>

<style lang="scss" scoped>
.delivery-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #59c6bc;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.ctkm {
  display: flex;
  align-items: center;
  &__label {
    font-size: 20px;
    font-weight: 500;
    line-height: 76px;
    min-width: 110px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 4px #5eb5af;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.75;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: solid 4px #19958c;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    margin-top: 20px;
  }
  &__item {
    height: 36px;
    line-height: 36px;
    background: rgba(243, 111, 32, 0.15);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 20px;
    &__name {
      font-size: 20px;
      font-weight: 600;
      color: #b93d00;
      text-transform: uppercase;
      padding: 0 8px;
      flex: 1;
    }
    &__close {
      border-left: solid 1px #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 8px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.75;
      }
      svg {
        transform: rotate(45deg);
      }
    }
  }
}
.ctkm__dialog {
}
.ctkm__dialog__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b93d00;
  padding: 16px 0;
  font-weight: 500;
  color: #b93d00;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #fae6da;
  }
  &--is-selected {
    background-color: #fae6da;
  }
}
.ctkm__dialog__item__code {
  font-weight: 600;
  width: 20%;
  max-width: 20%;
}
.ctkm__dialog__item__name {
  flex: 1;
}
</style>
